






import { Component, Vue } from "vue-property-decorator";
import PromoCampaignTable from "./PromoCampaignTable.vue";

@Component({ components: { PromoCampaignTable } })
export default class PromoCampaign extends Vue {}
