






















import { PromoCampaignType } from "@/enums/enums";
import { Component, Prop, Vue } from "vue-property-decorator";
import PromoCampaignPrivilegeDiscountCreate from "./PromoCampaignPrivilegeDiscountCreate.vue";
import PromoCampaignPrivilegePackageCreate from "./PromoCampaignPrivilegePackageCreate.vue";
import PromoCampaignPrivilegeExtraCreate from "./PromoCampaignPrivilegeExtraCreate.vue";
import { PromoCampaignModel } from "@/model/promoCode/promoCodeModel";

@Component({
  components: {
    PromoCampaignPrivilegeDiscountCreate,
    PromoCampaignPrivilegePackageCreate,
    PromoCampaignPrivilegeExtraCreate,
  },
})
export default class PromoCampaignPrivilegeCreate extends Vue {
  @Prop({}) promoCampaignCreateTemplate!: any;
  @Prop({}) promoCampaignList!: Array<PromoCampaignModel>;

  private PromoCampaignType = PromoCampaignType;
}
