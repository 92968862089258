


























































import { CustomerType, IsCommercial } from "@/enums/enums";
import { ValidateCreatePromoCampaign } from "@/helper/promoCodeHelper";
import { PromoCampaignModel } from "@/model/promoCode/promoCodeModel";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PromoCampaignInfoCreate extends Vue {
  @Prop({}) promoCampaignCreateTemplate!: any;
  @Prop({}) promoCampaignList!: Array<PromoCampaignModel>;

  private customerTypeList: Array<any> = [
    { value: CustomerType.Organic, text: "Organic" },
    { value: CustomerType.AFP, text: "AFP" },
    { value: CustomerType.BD, text: "BD" },
    { value: CustomerType.CSR, text: "CSR" },
    { value: CustomerType.Enterprise, text: "Enterprise" },
    { value: CustomerType.APIServices, text: "API services" },
    { value: CustomerType.PARC, text: "PARC" },
    { value: CustomerType.Test, text: "Test" },
    { value: CustomerType.ClientSupports, text: "Client supports" },
    { value: CustomerType.AFPFree, text: "AFPFree" },
    { value: CustomerType.BDFree, text: "BDFree" },
    { value: CustomerType.OtherInternalUses, text: "Other internal uses" },
    { value: CustomerType.Gifts, text: "Gifts" },
  ];
  private isCommercialList: Array<any> = [
    { value: IsCommercial.Yes, text: "Yes" },
    { value: IsCommercial.No, text: "No" },
  ];

  public OnChangeCreatePromoCampaign() {
    ValidateCreatePromoCampaign(this.promoCampaignCreateTemplate, this.promoCampaignList);
  }
}
