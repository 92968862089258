
























import { PromoCampaignType } from "@/enums/enums";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PromoCampaignConditionCreate extends Vue {
  @Prop({}) promoCampaignCreateTemplate!: any;

  get isPromoCampaignDiscount() {
    return this.promoCampaignCreateTemplate.type === PromoCampaignType.Discount;
  }
}
