























































































import { LoadingStatusType } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import { PromoCampaignModel } from "@/model/promoCode/promoCodeModel";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import PromoCampaignCreate from "./promoCampaignCreate/PromoCampaignCreate.vue";
import AuthService from "@/service/authService";

const auth = new AuthService();
const StorePromoCode = namespace("PromoCode");

@Component({ components: { PromoCampaignCreate } })
export default class PromoCampaignTable extends Vue {
  private textSearch = "";
  private table: any = {
    header: [],
    detail: [],
  };
  private action: any = [{ actionValue: 1, text: "แก้ไข" }];
  private pageNo = 1;
  private pageSize = 10;
  private actionValue = 0;
  private countItem = 0;
  private totalPage = 0;
  private pageNoList: Array<number> = [];
  private pageSizeList = [5, 10, 25, 50, 100];
  private isShowModalPromoCampaignCreate = false;
  permission = false;

  @StorePromoCode.Getter
  private getPromoCampaignList!: Array<PromoCampaignModel>;
  @StorePromoCode.Getter
  private getHeaderPromoCampaignTable!: Array<any>;
  @StorePromoCode.Getter
  private getPromoCampaignListLoadingStatus!: LoadingStatusType;
  @StorePromoCode.Getter
  private getCreatePromoCampaignLoadingStatus!: LoadingStatusType;

  @StorePromoCode.Action
  private DoGetPromoCampaignList!: () => void;
  
  constructor() {
    super();
    this.getPromoCampaignPermissions();
  }

  created() {
    this.DoGetPromoCampaignList();
  }

  @Watch("getPromoCampaignListLoadingStatus", { immediate: true })
  getPromoCampaignListLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      this.table.header = NewObject(this.getHeaderPromoCampaignTable);
      this.table.detail = this.getPromoCampaignList.length > 0 ? NewObject(this.getPromoCampaignList) : [];
      this.countItem = this.getPromoCampaignList.length;
      this.totalPage = Math.ceil(this.countItem / this.pageSize);
      this.pageNoList = [];
      for (let index = 0; index < this.totalPage; index++) {
        this.pageNoList.push(index + 1);
      }
    }
  }

  @Watch("getCreatePromoCampaignLoadingStatus")
  getCreatePromoCampaignLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      this.DoGetPromoCampaignList();
    }
  }

  public SearchPromoCampaign() {
    this.table.detail = this.getPromoCampaignList.filter((item: any) => {
      return item.name.toLowerCase().includes(this.textSearch.toLowerCase());
    });
  }

  public LabelNumber(index: number) {
    if (this.pageNo > 1) {
      return index + 1 + this.pageSize * (this.pageNo - 1);
    } else {
      return index + 1;
    }
  }

  public async getPromoCampaignPermissions() {
    const accessPermission = await auth.getPermissions();
    this.permission = accessPermission["promoCampain"]['create'];
  }
}
