






































import { PackageMonth, PeakPackage } from "@/enums/enums";
import { GetPackageList } from "@/helper/packageHelper";
import { SetPackagePeriodWithPackageId, ValidateCreatePromoCampaign } from "@/helper/promoCodeHelper";
import { PromoCampaignModel } from "@/model/promoCode/promoCodeModel";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class PromoCampaignPrivilegeExtraCreate extends Vue {
  @Prop({}) promoCampaignCreateTemplate!: any;
  @Prop({}) promoCampaignList!: Array<PromoCampaignModel>;

  private packageList = GetPackageList();
  private packageMonthList = [
    { value: PackageMonth.OneMonth, text: "1 Month" },
    { value: PackageMonth.ThreeMonth, text: "3 Month" },
    { value: PackageMonth.TwelveMonth, text: "12 Month" },
  ];

  created() {
    this.promoCampaignCreateTemplate.packageId = PeakPackage.Basic;
    this.promoCampaignCreateTemplate.packagePeriod.value = PackageMonth.OneMonth;
  }

  @Watch("promoCampaignCreateTemplate.packageId")
  packageIdChanged() {
    SetPackagePeriodWithPackageId(this.promoCampaignCreateTemplate);
  }

  public OnChangeCreatePromoCampaign() {
    ValidateCreatePromoCampaign(this.promoCampaignCreateTemplate, this.promoCampaignList);
  }
}
