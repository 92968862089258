





































































































import { PackageMonth, PackagePeriodType, PeakPackage } from "@/enums/enums";
import { GetPackageList } from "@/helper/packageHelper";
import { ValidateCreatePromoCampaign } from "@/helper/promoCodeHelper";
import { PromoCampaignModel } from "@/model/promoCode/promoCodeModel";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class PromoCampaignPrivilegePackageCreate extends Vue {
  @Prop({}) promoCampaignCreateTemplate!: any;
  @Prop({}) promoCampaignList!: Array<PromoCampaignModel>;

  private PackagePeriodType = PackagePeriodType;
  private packageList = GetPackageList();
  private packageId = PeakPackage.Basic;
  private periodList = [
    { value: PackagePeriodType.Month, text: "Month" },
    { value: PackagePeriodType.Day, text: "Day" },
    { value: PackagePeriodType.ExpiredDate, text: "Expired Date" },
  ];
  private packageMonthList = [
    { value: PackageMonth.OneMonth, text: "1 Month" },
    { value: PackageMonth.ThreeMonth, text: "3 Month" },
    { value: PackageMonth.TwelveMonth, text: "12 Month" },
  ];
  private packageMonth = PackageMonth.OneMonth;
  private modalDate = false;

  created() {
    this.promoCampaignCreateTemplate.packageId = PeakPackage.Basic;
  }

  @Watch("promoCampaignCreateTemplate.periodType")
  periodTypeChanged(newVal: PackagePeriodType) {
    if (newVal === PackagePeriodType.Month) {
      this.promoCampaignCreateTemplate.packagePeriodDay.value = 0;
      this.promoCampaignCreateTemplate.packageExpiredDate.value = null;
    } else if (newVal === PackagePeriodType.Day) {
      this.promoCampaignCreateTemplate.packagePeriod.value = 0;
      this.promoCampaignCreateTemplate.packageExpiredDate.value = null;
    } else if (newVal === PackagePeriodType.ExpiredDate) {
      this.promoCampaignCreateTemplate.packagePeriod.value = 0;
      this.promoCampaignCreateTemplate.packagePeriodDay.value = 0;
    }
  }

  public OnChangeCreatePromoCampaign() {
    ValidateCreatePromoCampaign(this.promoCampaignCreateTemplate, this.promoCampaignList);
  }
}
