









































import { LoadingStatusType, PromoCampaignType } from "@/enums/enums";
import { Component, Vue, Watch } from "vue-property-decorator";
import PromoCampaignInfoCreate from "./PromoCampaignInfoCreate.vue";
import PromoCampaignPrivilegeCreate from "./PromoCampaignPrivilegeCreate.vue";
import PromoCampaignConditionCreate from "./PromoCampaignConditionCreate.vue";
import {
  ConvertToPromoCampaignRequest,
  GetPromoCampaignCreateTemplateDefault,
  ValidateCreatePromoCampaign,
} from "@/helper/promoCodeHelper";
import { namespace } from "vuex-class";
import { PromoCampaignModel, PromoCampaignRequest } from "@/model/promoCode/promoCodeModel";

const StorePromoCode = namespace("PromoCode");

@Component({ components: { PromoCampaignInfoCreate, PromoCampaignPrivilegeCreate, PromoCampaignConditionCreate } })
export default class PromoCampaignCreate extends Vue {
  private showModal = true;
  private PromoCampaignType = PromoCampaignType;
  private promoCampaignCreateTemplate = GetPromoCampaignCreateTemplateDefault();

  @StorePromoCode.Getter
  private getCreatePromoCampaignLoadingStatus!: LoadingStatusType;
  @StorePromoCode.Getter
  private getPromoCampaignList!: Array<PromoCampaignModel>;
  @StorePromoCode.Getter
  private getPromoCampaignListLoadingStatus!: LoadingStatusType;

  @StorePromoCode.Action
  private DoGetPromoCampaignList!: () => void;

  created() {
    this.DoGetPromoCampaignList();
  }

  @StorePromoCode.Action
  private DoCreatePromoCampaign!: (input: PromoCampaignRequest) => void;

  @Watch("promoCampaignCreateTemplate.type")
  promoCampaignTypeChanged(newVal: PromoCampaignType) {
    switch (newVal) {
      case PromoCampaignType.Discount:
        this.promoCampaignCreateTemplate.packagePrice.value = "0.00";
        this.promoCampaignCreateTemplate.packagePeriodExtra.value = "0";
        break;
      case PromoCampaignType.Package:
        this.promoCampaignCreateTemplate.discountNumber.value = "0.00";
        this.promoCampaignCreateTemplate.discountPercent.value = "0.00";
        this.promoCampaignCreateTemplate.packagePeriodExtra.value = "0";
        break;
      case PromoCampaignType.Extra:
        this.promoCampaignCreateTemplate.discountNumber.value = "0.00";
        this.promoCampaignCreateTemplate.discountPercent.value = "0.00";
        this.promoCampaignCreateTemplate.packagePrice.value = "0.00";
        break;
    }
  }

  @Watch("showModal")
  showModalChanged(newVal: boolean) {
    if (!newVal) {
      this.HideModal();
    }
  }

  public CreatePromoCampaign() {
    const isValidateError = ValidateCreatePromoCampaign(this.promoCampaignCreateTemplate, this.getPromoCampaignList);
    if (!isValidateError) {
      const promoCampaignRequest = ConvertToPromoCampaignRequest(this.promoCampaignCreateTemplate);
      this.DoCreatePromoCampaign(promoCampaignRequest);
      this.showModal = false;
    }
  }

  public OnChangeCreatePromoCampaign() {
    ValidateCreatePromoCampaign(this.promoCampaignCreateTemplate);
  }

  public HideModal() {
    this.$emit("update:showModal", false);
  }
}
