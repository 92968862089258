
































































import { PackageMonth, PeakPackage, PromoCampaignDiscountType } from "@/enums/enums";
import { GetPackageList } from "@/helper/packageHelper";
import { SetPackagePeriodWithPackageId, ValidateCreatePromoCampaign } from "@/helper/promoCodeHelper";
import { PromoCampaignModel } from "@/model/promoCode/promoCodeModel";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class PromoCampaignPrivilegeDiscountCreate extends Vue {
  @Prop({}) promoCampaignCreateTemplate!: any;
  @Prop({}) promoCampaignList!: Array<PromoCampaignModel>;

  private discountTypeList = [
    { value: PromoCampaignDiscountType.Amount, text: "Discount Amount" },
    { value: PromoCampaignDiscountType.Percent, text: "Discount Percent" },
  ];
  private packageList = GetPackageList(true);
  private packageMonthList = [
    { value: PackageMonth.OneMonth, text: "1 Month" },
    { value: PackageMonth.ThreeMonth, text: "3 Month" },
    { value: PackageMonth.TwelveMonth, text: "12 Month" },
    { value: PackageMonth.None, text: "Not specify" },
  ];

  get isDiscountTypeAmount() {
    return this.promoCampaignCreateTemplate.discountType === PromoCampaignDiscountType.Amount;
  }

  @Watch("promoCampaignCreateTemplate.discountType")
  discountTypeChanged(newVal: PromoCampaignDiscountType) {
    if (newVal === PromoCampaignDiscountType.Amount) {
      this.promoCampaignCreateTemplate.discountPercent.value = "0.00";
    } else {
      this.promoCampaignCreateTemplate.discountNumber.value = "0.00";
    }
  }

  @Watch("promoCampaignCreateTemplate.packageId")
  packageIdChanged() {
    SetPackagePeriodWithPackageId(this.promoCampaignCreateTemplate);
  }

  public OnChangeCreatePromoCampaign() {
    ValidateCreatePromoCampaign(this.promoCampaignCreateTemplate, this.promoCampaignList);
  }
}
